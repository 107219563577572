import React from 'react';
import styled from 'styled-components';

const FooterStyles = styled.footer`
  background-color: lightgrey;
  min-height: 150px;
  padding: 20px 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default function Footer(){
  const year = new Date().getFullYear();
  return (
    <FooterStyles>
      <p>&copy; Advanced Concepts Seminars - {year}</p>
    </FooterStyles>
  )
}