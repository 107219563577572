import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react"
import styled from 'styled-components';

const NavbarStyles = styled.nav`
  height: fit-content;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: white;
  display: block;
  .navbar-container {
    max-width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 750px) {
      flex-direction: column;
    }
    ul {
      display: grid;
      grid-template-columns: repeat(3, minmax(150px, 1fr));
      list-style-type: none;
      @media (max-width: 750px) {
        grid-template-columns: 1fr;
        text-align: center;
      }
      li {
        margin-right: 20px;
        border: 1px solid grey;
        text-align: center;
        padding: 12px 24px;
        border-radius: 8px;
        background-color: silver;
        @media (max-width: 750px) {
          margin-right: 50px;
          margin-top: 15px
        }
        a {
          text-decoration: none;
        }
      }
    }
  }
`;

export default function Navbar() {
  const {logo} = useStaticQuery(graphql`
    query {
      logo: allSanityLogoImage(filter: {name: {eq: "acs logo"}}) {
        nodes {
          id
          name
          image {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 150)
            }
          }
        }
      }
    }
  `)
  // console.log({logo});
  return (
    <NavbarStyles>
      <div className="navbar-container">
        <GatsbyImage image={logo.nodes[0].image.asset.gatsbyImageData
} height={50} alt={logo.nodes[0].name} />
        <ul>
          <li>
          <Link to="/">Home</Link>
          </li>
          <li>
          <Link to="/courses">Courses</Link>
          </li>
          <li>
          <Link to="/instructors">Instructors</Link>
          </li>
        </ul>
      </div>
    </NavbarStyles>
  )
}