import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`

  html {
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }
  p, li {
    letter-spacing: 0.5px;
    font-weight: 400;
  }
  h1 {
    font-weight: 900;
  }
  h2 {
    font-weight: 700;
  }
  h3 {
    font-weight: 500;
  }
  h4, h5, h6 {
    font-weight: normal;
  }
  h1,h2,h3,h4,h5,h6 {
    margin: 0;
  }
  a {
    color: var(--black);
  }
  mark, .mark {
    background: var(--yellow);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }
  .center {
    text-align: center;
  }
`;

export default Typography;